// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
@import './themes/palette';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cta-link-generator-primary: mat-palette($srd-primary);
$cta-link-generator-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cta-link-generator-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cta-link-generator-theme: mat-light-theme(
  (
    color: (
      primary: $cta-link-generator-primary,
      accent: $cta-link-generator-accent,
      warn: $cta-link-generator-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($cta-link-generator-theme);

/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Catamaran', sans-serif;
}

.pointer {
  cursor: pointer;
}

.mat-dialog-container .mat-dialog-content {
  padding-top: 4px;
  padding-bottom: 4px;
}
